import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import PopUp from "./PopUp";
import api from "./api/api";
import functions from "../functions/functions";

export default function CashDrawerPopup() {
	const { user, setLoading, cashDrawerPopupVisible, setCashDrawerPopupVisible, setUser, shop } =
		useContext(AppContext);
	const [data, setData] = useState(false);
	const [visible, setVisible] = useState(false);
	const [totalOpen, setTotalOpen] = useState(0);
	const [totalClose, setTotalClose] = useState(0);

	useEffect(() => {
		loadData();
	}, [user]);

	useEffect(() => {
		check();
	}, [data, cashDrawerPopupVisible]);

	const loadData = async () => {
		if (user) {
			const response = await api.postProtected("/cash_drawer/check/" + user.id + "/", {
				pos_id: localStorage.getItem("pos-id"),
			});
			if (response.success == 0) alert(response.error);
			else setData(response.data);
		} else setData(false);
	};

	const add = async (request) => {
		setLoading(true);
		const res = await api.postProtected("/cash_drawer/add/", request);
		setLoading(false);
		if (res.success == 1) {
			loadData();
		} else
			setConfirm({
				title: "Errore",
				message: res.error,
			});
	};

	const edit = async (id, request) => {
		setLoading(true);
		const res = await api.postProtected("/cash_drawer/edit/" + id + "/", request);
		setLoading(false);
		if (res.success == 1) {
			loadData();
		} else
			setConfirm({
				title: "Errore",
				message: res.error,
			});
	};

	const check = async () => {
		if (user) {
			if (data) {
				if (data.unclosed) setVisible("unclosed");
				else if (!data.current) setVisible("current-open");
				else if (cashDrawerPopupVisible) setVisible("current-close");
				else setVisible(false);
			} else setVisible(false);
		} else setVisible(false);
	};

	if (!visible) return;

	return (
		<PopUp
			size=""
			options={{
				onClose: false,
				title: visible == "unclosed" ? "Chiusura Turno" : "Apertura Turno",
			}}
		>
			<div className="inner-content">
				{visible == "unclosed" && (
					<div>
						<h2>Fondo cassa</h2>
						<p>
							Valore iniziale: {functions.formatter.format(data.unclosed.total_open)}
						</p>
						<p>Inserire il valore del fondo cassa</p>
						<input
							type="number"
							min="0"
							step="0.01"
							value={totalClose}
							onChange={(e) => setTotalClose(e.target.value)}
						/>
						<button
							onClick={() => edit(data.unclosed.id, { total_close: totalClose })}
							className="button mt-2"
						>
							Chiudi turno
						</button>
					</div>
				)}
				{visible == "current-open" && (
					<div>
						<h2>Fondo cassa</h2>
						<p>Inserire il valore del fondo cassa</p>
						<input
							type="number"
							min="0"
							step="0.01"
							value={totalOpen}
							onChange={(e) => setTotalOpen(e.target.value)}
						/>
						<button
							onClick={() =>
								add({
									pos_id: localStorage.getItem("pos-id"),
									user_id: user.id,
									shop_id: shop.id,
									total_open: totalOpen,
								})
							}
							className="button mt-2"
						>
							Apri turno
						</button>
					</div>
				)}
				{visible == "current-close" && (
					<div>
						<h2>Fondo cassa</h2>
						<p>
							Valore iniziale: {functions.formatter.format(data.current.total_open)}
						</p>
						<p>Inserire il valore del fondo cassa</p>
						<input
							type="number"
							min="0"
							step="0.01"
							value={totalClose}
							onChange={(e) => setTotalClose(e.target.value)}
						/>
						<button
							onClick={() => {
								edit(data.current.id, { total_close: totalClose });
								setCashDrawerPopupVisible(false);
								setUser(false);
								localStorage.removeItem("pos-user");
							}}
							className="button mt-2"
						>
							Chiudi turno
						</button>
					</div>
				)}
			</div>
		</PopUp>
	);
}
