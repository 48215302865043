import { useEffect } from "react";

function Socket({ socket, mandantId, onMessage, shop, config, ordersFilter }) {
	useEffect(() => {
		console.log("add socket listener");
		socket.on("connect", onConnect);
		socket.on("disconnect", onDisconnect);
		socket.on("subscribed", onSubrscribed);
		socket.on("events", onSocketEvents);

		onConnect();

		return () => {
			console.log("remove socket listener");
			socket.off("connect", onConnect);
			socket.off("disconnect", onDisconnect);
			socket.off("subscribed", onSubrscribed);
			socket.off("events", onSocketEvents);
		};
	}, [shop, config, ordersFilter]);

	function onConnect() {
		console.log("Socket connected", socket.id);
		const hostname = window.location.hostname;
		if (hostname.includes("localhost")) mandantId = mandantId + "_localhost";
		if (hostname.includes("demo")) mandantId = mandantId + "_demo";

		socket.emit("subscribe", "m" + mandantId + ".settings.#");
		socket.emit("subscribe", "m" + mandantId + ".shops.#");
		socket.emit("subscribe", "m" + mandantId + ".menu.#");
		socket.emit("subscribe", "m" + mandantId + ".pos_categories.#");
		socket.emit("subscribe", "m" + mandantId + ".fiscal_printers.#");
		socket.emit("subscribe", "m" + mandantId + ".users.#");
		socket.emit("subscribe", "m" + mandantId + ".roles.#");
		socket.emit("subscribe", "m" + mandantId + ".s" + shop.id + ".tables.#");
		socket.emit("subscribe", "m" + mandantId + ".s" + shop.id + ".orders.#");
		socket.emit("subscribe", "m" + mandantId + ".s" + shop.id + ".notifications.#");
		socket.emit("subscribe", "m" + mandantId + ".products_stock.#");
		socket.emit("subscribe", "m" + mandantId + ".pos_colors.#");
		if (config.mode == "local") socket.emit("subscribe", "m" + mandantId + ".cloudStatus");
	}

	function onDisconnect() {
		console.log("Socket disconnected");
	}

	function onSubrscribed(data) {
		console.log(data);
	}

	function onSocketEvents(message) {
		console.log("new socket event: ", message);
		onMessage(message.table, message.data);
	}

	return null;
}

export default Socket;
