import { useContext } from "react";
import { AppContext } from "../../App";
import "./confirm.css";

function Confirm({}) {
	const { confirm, setConfirm } = useContext(AppContext);

	if (!confirm) return null;

	return (
		<div className="confirm">
			<div className="content">
				{confirm.title && <div className="title">{confirm.title}</div>}
				{confirm.message && <div className="message">{confirm.message}</div>}
				{confirm.onConfirm ? (
					<div className="buttons">
						<button
							onClick={() => {
								setConfirm(false);
								if (confirm.onCancel) confirm.onCancel();
							}}
						>
							No
						</button>
						<button
							className="primary"
							onClick={() => {
								confirm.onConfirm();
								setConfirm(false);
							}}
						>
							Si
						</button>
					</div>
				) : (
					<button className="primary" onClick={() => setConfirm(false)}>
						Ok
					</button>
				)}
			</div>
		</div>
	);
}

export default Confirm;
