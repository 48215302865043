import { useContext, useEffect, useState } from "react";
import Api from "./api/api";
import { AppContext } from "../App";
import jwt_decode from "jwt-decode";
import logo from "../images/logo.png";

function Login() {
	const { shops, setUser, shop, setShop } = useContext(AppContext);
	const [shopId, setShopId] = useState(localStorage.getItem("pos-shop-id") || "");

	useEffect(() => {
		console.log("shopId set to", shopId);
		localStorage.setItem("pos-shop-id", shopId);
		if (shopId) {
			const selected = shops.find((s) => s.id == shopId);
			if (selected) setShop(selected);
		}
	}, [shopId]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		const data = new FormData(e.target);
		let request = Object.fromEntries(data.entries());
		const login = await Api.login(request);
		if (login.success == 0) alert(login.error);
		else {
			const decoded = jwt_decode(login.token);
			localStorage.setItem("pos-user", login.token);
			const response = await Api.getProtected("/users/get/" + decoded.user_id + "/");
			if (response.success == 0) alert(response.error);
			else setUser(response.data);
		}
	};

	return (
		<div className="login">
			<form className="content" onSubmit={handleSubmit}>
				<div className="login-logo">
					<img src={logo} />
				</div>
				<h2>YellGO POS</h2>
				<select
					name="shop_id"
					onChange={(e) => setShopId(e.target.value)}
					value={shopId}
					required
				>
					<option value=""></option>
					{shops.map((s, i) => (
						<option key={i} value={s.id}>
							{s.name}
						</option>
					))}
				</select>
				<input type="email" name="email" placeholder="Email" autoComplete="username" />
				<input
					type="password"
					name="password"
					autoComplete="current-password"
					placeholder="Password"
				/>
				<button className="button button-1">Accedi</button>
			</form>
		</div>
	);
}

export default Login;
