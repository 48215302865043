import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import api from "../api/api";
import List from "../List";
import functions from "../../functions/functions";
import moment from "moment/moment";
import FormGroup from "../form/FormGroup";
import Flows from "./Flows";
import TrashIcon from "../../icons/TrashIcon";
import Takeaway from "../../icons/Takeaway";
import Table from "../../icons/Table";
import Delivery from "../../icons/Delivery";
import HamburgerIcon from "../../icons/HamburgerIcon";
import NoteIcon from "../../icons/NoteIcon";
import InvoiceIcon from "../../icons/InvoiceIcon";
import BillIcon from "../../icons/BillIcon";
import PretotalIcon from "../../icons/PretotalIcon";
import SendIcon from "../../icons/SendIcon";
import DotIcon from "../../icons/DotIcon";
import ExpandIcon from "../../icons/ExpandIcon";
import CompressIcon from "../../icons/CompressIcon";

function Cart() {
	const {
		apiSettings,
		cart,
		setCart,
		editCart2,
		init_cart,
		setPopup,
		shop,
		setConfirm,
		activeFlow,
		setEditingProduct,
		edited,
		setEdited,
		config,
		setSplitPopup,
		rooms,
		checkout,
		setInvoicePopup,
		updateOrderFromCart,
		total,
		setTotal,
		deliveryPrice,
		setDeliveryPrice,
		round,
		setRound,
		checkPermission,
		askPermission,
		setOverlay,
		setPaymentPopup,
		setRestPopup,
	} = useContext(AppContext);
	const [valid, setValid] = useState(false);
	const [error, setError] = useState(false);
	const [subMenuOpen, setSubMenuOpen] = useState(false);
	const [points, setPoints] = useState(0);

	useEffect(() => {
		if (cart) {
			check();
			calculateTotal();
			localStorage.setItem("pos-delivery_type", JSON.stringify(cart.delivery_type));
		}
	}, [cart, round, apiSettings]);

	useEffect(() => {
		calculatePoints();
	}, [total]);

	const check = () => {
		console.log("check");
		let error = false;
		let passed = true;

		if (!cart.shop_id) passed = false;
		if (cart.products.length == 0) {
			error = "Nessun prodotto";
			passed = false;
		}

		if (cart.delivery_type.id == "takeaway") {
			if (!cart.delivery_date) {
				error = "Manca la data di ritiro";
				passed = false;
			}
		}

		if (cart.delivery_type.id == "delivery") {
			if (
				!cart.customer &&
				cart.origin != "glovo" &&
				cart.origin != "deliveroo" &&
				cart.origin != "justeat"
			) {
				error = "Manca il cliente";
				passed = false;
			} else if (!cart.address) {
				error = "Manca l'indirizzo";
				passed = false;
			} else if (!cart.delivery_date) {
				error = "Manca la data di consegna";
				passed = false;
			}
		}

		if (apiSettings.pos.mandatory_customer == 1 && !cart.customer) {
			error = "Manca il cliente";
			passed = false;
		}

		setValid(passed);
		setError(error);
	};

	const calculateTotal = () => {
		//console.log("calculateTotal");
		let total = 0;
		for (const product of cart.products) {
			total += product.qty * product.price;
		}
		for (let index = 0; index < cart.discounts.length; index++) {
			if (
				cart.discounts[index].value_type == "perc" ||
				cart.discounts[index].value_type == "percent"
			) {
				const val = (total / 100) * cart.discounts[index].value;
				total -= val;
				cart.discounts[index].price = val;
			} else {
				total -= cart.discounts[index].value;
				cart.discounts[index].price = cart.discounts[index].value;
			}
		}
		console.log(
			"delivery_price",
			cart.delivery_type.id,
			cart.address?.delivery_free_min,
			cart.address?.delivery_price
		);
		let delivery = 0;
		if (cart.delivery_type.id == "delivery" && cart.address) {
			delivery = parseFloat(cart.address.delivery_price);
			if (
				cart.address.delivery_free_min &&
				total >= parseFloat(cart.address.delivery_free_min)
			)
				delivery = 0;
		}
		if (cart.credit_total) total -= parseFloat(cart.credit_total);
		total += parseFloat(delivery);

		const round = calculateRound(total);
		total += round;

		setDeliveryPrice(delivery);
		setRound(round);
		setTotal(total);
	};

	const calculatePoints = () => {
		let points = 0;
		if (apiSettings.fidelity?.active == 1 && cart.customer && total > 0)
			points = parseInt((total / apiSettings.fidelity.value) * apiSettings.fidelity.points);
		setPoints(points);
	};

	const calculateRound = (total) => {
		let round = 0;
		if (apiSettings.pos.order_round == 2) {
			const roundedTotal = roundNumber(total, 1, apiSettings.pos.order_round_mode);
			round = roundNumber(roundedTotal - total);
			console.log(total, roundedTotal, round);
		}
		if (apiSettings.pos.order_round == 3) {
			const roundedTotal = roundNumber(total, 0, apiSettings.pos.order_round_mode);
			round = roundNumber(roundedTotal - total);
			console.log(total, roundedTotal, round);
		}
		return round;
	};

	const roundNumber = (number, decimals = 2, mode = 1) => {
		let newnumber = number;
		console.log(newnumber);
		if (decimals == 1) newnumber = newnumber * 10;
		if (decimals == 2) newnumber = newnumber * 100;
		console.log(newnumber);
		if (mode == 1) newnumber = Math.round(newnumber);
		if (mode == 2) newnumber = Math.ceil(newnumber);
		if (mode == 3) newnumber = Math.floor(newnumber);
		console.log(newnumber);
		if (decimals == 1) newnumber = newnumber / 10;
		if (decimals == 2) newnumber = newnumber / 100;
		console.log(newnumber, mode);
		return parseFloat(newnumber);
	};

	const removeDiscount = (i) => {
		let nCart = { ...cart };
		nCart.discounts.splice(i, 1);
		setCart(nCart);
	};

	const generateCovers = () => {
		let res = [];
		for (let i = 1; i <= 20; i++) {
			res.push({ id: i, name: i });
		}
		return res;
	};

	const cancelOrder = async () => {
		if (checkPermission("orders", "archive")) {
			setConfirm({
				title: "Sei sicuro?",
				message: "Procedendo annullerai l'ordine corrente",
				onConfirm: async () => {
					setSubMenuOpen(false);
					setOverlay(false);
					init_cart();
					const id = config.mode == "local" ? cart["$loki"] : cart.order_id;
					await api.postProtected("/orders/edit/" + id + "/", {
						status: "archived",
					});
				},
			});
		} else {
			askPermission();
		}
	};

	const printCopy = async () => {
		const id = config.mode == "local" ? cart["$loki"] : cart.order_id;
		await api.postProtected("/orders/edit/" + id + "/", {
			printed: 0,
		});
	};

	const isEditing = () => {
		//console.log("isEditing", cart);
		let res = false;
		if (config.mode == "local" && cart["$loki"]) res = true;
		else if (cart.order_id) res = true;
		//console.log("isEditing", res);
		return res;
	};

	const remove = (i) => {
		setConfirm({
			title: "Sei sicuro?",
			message: "",
			onConfirm: async () => {
				let nCart = { ...cart };
				nCart.products.splice(i, 1);
				setCart(nCart);
				setEdited(true);
				setEditingProduct(false);
			},
		});
	};

	const showError = () => {
		setConfirm({
			title: "Errore",
			message: error,
		});
	};

	if (!cart) return null;

	return (
		<div className="cart">
			<div className="cart-box expand">
				<div className="cart-header">
					<div className="cart-header-buttons-container">
						<div className="cart-header-buttons">
							<button>
								{cart.delivery_type.id == "delivery" && <Delivery />}
								{cart.delivery_type.id == "takeaway" && <Takeaway />}
								{cart.delivery_type.id == "shop" && <Table />}
							</button>
							{cart.delivery_type && cart.table && (
								<div className="label">
									<div className="">{cart.table.name}</div>
									<div className="small">
										{rooms.find((r) => r.id == cart.room_id)?.name}
									</div>
								</div>
							)}
						</div>
						<div className="cart-header-buttons">
							<button
								onClick={() => {
									setSubMenuOpen(false);
									setOverlay(false);
									setPopup({
										title: "Coperti",
										content: (
											<List
												mode={3}
												elements={generateCovers()}
												onClick={(el) => {
													setPopup(false);
													editCart2({ covers: el.id });
												}}
											/>
										),
									});
								}}
							>
								{cart.covers}
							</button>
							<button
								onClick={() => {
									setSubMenuOpen(false);
									setOverlay(false);
									setPopup({
										title: "Note ordine",
										content: (
											<FormGroup
												type="textarea"
												value={cart.notes}
												onChange={(e) => {
													editCart2({ notes: e.target.value });
												}}
											/>
										),
									});
								}}
							>
								<NoteIcon />
							</button>
							<div className="cart-submenu-container">
								<button
									onClick={() => {
										setOverlay({
											action: () => {
												setSubMenuOpen(false);
												setOverlay(false);
											},
										});
										setSubMenuOpen(true);
									}}
								>
									<HamburgerIcon />
								</button>
								{subMenuOpen && (
									<div className="cart-submenu">
										{isEditing() && (
											<>
												{!edited && (
													<button
														onClick={() => {
															setSubMenuOpen(false);
															setOverlay(false);
															setSplitPopup({
																mode: "cart",
																order: { ...cart, total: total },
															});
														}}
													>
														Dividi
													</button>
												)}
												{apiSettings.mandant.printers == 1 && (
													<button
														onClick={() => {
															setConfirm({
																title: "Stampare copia comande?",
																message: "",
																onConfirm: () => {
																	console.log("here");
																	setSubMenuOpen(false);
																	setOverlay(false);
																	printCopy();
																},
															});
														}}
													>
														Ristampa comande
													</button>
												)}
												<button onClick={() => cancelOrder()}>
													Archivia Ordine
												</button>
											</>
										)}
										<button
											onClick={() =>
												setConfirm({
													title: "Sei sicuro?",
													message: "",
													onConfirm: () => {
														setSubMenuOpen(false);
														setOverlay(false);
														init_cart();
													},
												})
											}
										>
											Nuovo Ordine
										</button>
									</div>
								)}
							</div>
						</div>
					</div>
					<div>
						{cart.customer && (
							<div className="small">
								Cliente: {cart.customer.name} {cart.customer.surname}
							</div>
						)}
						{cart.address && cart.address.address && (
							<div className="small">
								Indirizzo: {cart.address.address} {cart.address.number},{" "}
								{cart.address.city} ({cart.address.doorphone})
							</div>
						)}
						{cart.delivery_date && (
							<div className="small">
								Data:{" "}
								{moment(cart.delivery_date, "YYYY-MM-DD HH:mm:ss").format(
									"DD/MM/YYYY HH:mm"
								)}
							</div>
						)}
						{isEditing(cart) && <div className="small">Ordine {cart.order_id}</div>}
					</div>
					<Flows />
				</div>
				<div className="scroll">
					{cart.products.map(
						(prod, i) =>
							prod.flow == activeFlow && (
								<div key={i} className="cart-product-line">
									<div className="cart-product-line-qty">{prod.qty}</div>
									<button
										className="cart-product-line-name"
										onClick={() => setEditingProduct({ i: i, data: prod })}
									>
										<div className="cart-product-name">{prod.name}</div>
										<div className="small">
											{prod.variants_categories.map(
												(vc, n) =>
													vc.selected > 0 && (
														<div key={n}>
															<div className="bold">{vc.name}</div>
															{vc.variants.map(
																(v, x) =>
																	v.qty > 0 && (
																		<div key={x}>
																			{v.qty + "X " + v.name}
																		</div>
																	)
															)}
															{vc.variants2.map(
																(v, x) =>
																	v.qty > 0 && (
																		<div key={x}>
																			{v.qty + "X " + v.name}
																		</div>
																	)
															)}
															{vc.products.map(
																(v, x) =>
																	v.qty > 0 && (
																		<div key={x}>
																			{v.qty + "X " + v.name}
																		</div>
																	)
															)}
														</div>
													)
											)}
											{prod.ingredients.map(
												(v, x) =>
													v.removed && <div key={x}>{"No " + v.name}</div>
											)}
										</div>
										{prod.notes && (
											<div
												className="small"
												style={{ whiteSpace: "pre-wrap" }}
											>
												<b>Note prodotto</b>
												<br />
												{prod.notes}
											</div>
										)}
									</button>
									<div key={i} className="cart-product-line-right">
										<div className="cart-product-line-price">
											{functions.formatter.format(prod.price * prod.qty)}
										</div>
										<button
											className="button button-2 square"
											onClick={() => remove(i)}
										>
											<TrashIcon />
										</button>
									</div>
								</div>
							)
					)}
				</div>
				{cart.discounts.map((d, i) => (
					<div key={i} className="subtotal">
						<div>
							<button className="button-2" onClick={() => removeDiscount(i)}>
								<TrashIcon />
							</button>
							<div>
								{d.name +
									(d.value_type == "perc" || d.value_type == "percent"
										? " " + d.value + "%"
										: "")}
							</div>
						</div>
						<div>
							{d.price > 0 && "-"}
							{functions.formatter.format(d.price > 0 ? d.price : -d.price)}
						</div>
					</div>
				))}
				{deliveryPrice > 0 && (
					<div className="subtotal">
						<div>Consegna</div>
						<div>{functions.formatter.format(deliveryPrice)}</div>
					</div>
				)}
				{cart.credit_total > 0 && (
					<div className="subtotal">
						<div>Credito cliente</div>
						<div>-{functions.formatter.format(cart.credit_total)}</div>
					</div>
				)}
				{round != 0 && (
					<div className="subtotal">
						<div>Arrotondamento:</div>
						<div>{functions.formatter.format(round)}</div>
					</div>
				)}
				<div>
					<div className="total">
						<div>Totale:</div>
						<div className="total-price">{functions.formatter.format(total)}</div>
					</div>
					<div
						style={{
							whiteSpace: "pre-wrap",
							display: "flex",
							alignItems: "flex-start",
							justifyContent: "space-between",
							gap: "5px",
						}}
					>
						<div
							className="small"
							style={{
								whiteSpace: "pre-wrap",
								display: "flex",
								alignItems: "center",
								gap: "5px",
							}}
						>
							<div
								className={
									"payed-icon" +
									(cart.payment_method?.prepaid == 1
										? " text-success"
										: " text-error")
								}
							>
								<DotIcon />
							</div>
							{cart.payment_method?.prepaid == 1 ? "Pagato" : "Non pagato"}
						</div>
						{points > 0 && <div className="small">Punti guadagnati: {points}</div>}
					</div>
				</div>
				{cart.notes && (
					<div className="small" style={{ whiteSpace: "pre-wrap" }}>
						<b>Note Ordine</b>
						<br />
						{cart.notes}
					</div>
				)}
			</div>
			<Keypad
				saveButton={{
					enabled: valid,
					text: "Invia",
					icon: <SendIcon />,
					class: "bill",
					action: () => {
						if (valid) {
							if (isEditing()) {
								updateOrderFromCart();
							} else {
								if (config.optional_print == 1) {
									setConfirm({
										title: "Stampare comanda?",
										message: "",
										onConfirm: async () => {
											checkout(config.keep_open_after_checkout == 1);
										},
										onCancel: async () => {
											checkout(
												config.keep_open_after_checkout == 1,
												false,
												false,
												false,
												false,
												{ printed: 1 }
											);
										},
									});
								} else {
									checkout(config.keep_open_after_checkout == 1);
								}
							}
						} else showError();
					},
				}}
				preBillButton={{
					enabled: valid,
					text: "Preconto",
					icon: <PretotalIcon />,
					class: "bill",
					action: () => {
						if (valid) {
							if (isEditing()) {
								if (edited) {
									updateOrderFromCart(false, false, true);
								} else {
									updateOrderFromCart(false, false, true);
								}
							} else {
								checkout(
									config.keep_open_after_checkout == 1,
									false,
									false,
									false,
									true
								);
							}
						} else showError();
					},
				}}
				invoiceButton={{
					enabled: valid,
					text: "Fattura",
					icon: <InvoiceIcon />,
					class: "bill",
					action: () => {
						if (valid) {
							setInvoicePopup(true);
						} else showError();
					},
				}}
				billButton={{
					enabled: valid,
					text:
						apiSettings.mandant.fiscal_printers == 1 || apiSettings.mandant.fiskaly == 1
							? "Scontrino"
							: "Completa",
					icon: <BillIcon />,
					class: "bill large",
					action: () => {
						if (valid) {
							if (isEditing()) {
								if (
									total == 0 ||
									(cart.payment_method?.prepaid == 1 &&
										config.skip_payment_request_for_payed == 1)
								) {
									updateOrderFromCart(
										false,
										true,
										config.prebill_on_checkout == 1
									);
								} else {
									if (
										apiSettings.mandant.fiscal_printers == 1 ||
										apiSettings.mandant.fiskaly == 1 ||
										apiSettings.pos.fast_checkout == 0
									) {
										//setPaymentPopup(true);
										setPopup({
											title: "Modalità di pagamento",
											content: (
												<List
													mode={6}
													type="payment_methods"
													elements={shop.payment_methods}
													onClick={(choiche) => {
														setPopup(false);
														if (
															config.rest == 1 &&
															choiche &&
															choiche.id == 1
														)
															setRestPopup(true);
														else if (choiche && choiche.id == 14)
															updateOrderFromCart(
																choiche,
																false,
																false,
																true
															);
														else
															updateOrderFromCart(
																choiche,
																true,
																config.prebill_on_checkout == 1
															);
													}}
												/>
											),
										});
									} else {
										updateOrderFromCart(
											false,
											true,
											config.prebill_on_checkout == 1
										);
									}
								}
							} else {
								if (
									apiSettings.mandant.fiscal_printers == 1 ||
									apiSettings.mandant.fiskaly == 1 ||
									apiSettings.pos.fast_checkout != 1
								) {
									//setPaymentPopup(true);
									setPopup({
										title: "Modalità di pagamento",
										content: (
											<List
												mode={6}
												type="payment_methods"
												elements={shop.payment_methods}
												onClick={(choiche) => {
													setPopup(false);
													if (
														config.rest == 1 &&
														choiche &&
														choiche.id == 1
													) {
														setRestPopup(true);
													} else if (choiche && choiche.id == 14)
														checkout(
															false,
															choiche,
															false,
															true,
															config.prebill_on_checkout == 1
														);
													else
														checkout(
															false,
															choiche,
															true,
															false,
															config.prebill_on_checkout == 1
														);
												}}
											/>
										),
									});
								} else {
									checkout(
										false,
										false,
										true,
										false,
										config.prebill_on_checkout == 1
									);
								}
							}
						} else showError();
					},
				}}
			/>
		</div>
	);
}

function Keypad({ billButton, invoiceButton, preBillButton, saveButton }) {
	const { amount, setAmount, command, setCommand, cart, setCart } = useContext(AppContext);
	const [open, setOpen] = useState(localStorage.getItem("pos-keypad-open") || true);

	useEffect(() => {
		if (amount == "") {
			setCommand(false);
		}
	}, [amount]);

	useEffect(() => {
		localStorage.setItem("pos-keypad-open", open);
	}, [open]);

	const addDiscount = () => {
		let nCart = { ...cart };
		if (amount) {
			if (command == "") {
				nCart.discounts.push({
					name: "Supplemento",
					type: "fixed",
					value_type: "fixed",
					value: -parseFloat(amount),
				});
			}
			if (command == "multi") {
			}
			if (command == "minus") {
				nCart.discounts.push({
					name: "Sconto cassa",
					type: "fixed",
					value_type: "fixed",
					value: parseFloat(amount),
				});
			}
			if (command == "percminus") {
				nCart.discounts.push({
					name: "Sconto cassa",
					type: "perc",
					value_type: "perc",
					value: parseFloat(amount),
				});
			}
			if (command == "percplus") {
				nCart.discounts.push({
					name: "Supplemento",
					type: "perc",
					value_type: "perc",
					value: -parseFloat(amount),
				});
			}
		}
		/*
		nCart.discounts.push({
			name: isDiscount ? "Sconto cassa" : "Supplemento",
			type: type,
			value_type: type,
			value: isDiscount ? value : -value,
		});*/
		setCart(nCart);
		setAmount("");
		setCommand(false);
	};

	return (
		<div className={"keypad" + (open ? " open" : " closed")}>
			<button className="expand-button" onClick={() => setOpen(open ? false : true)}>
				{open ? (
					<>
						<CompressIcon></CompressIcon>
					</>
				) : (
					<>
						<ExpandIcon></ExpandIcon>
					</>
				)}
			</button>
			<div className="keypad-amount open-only">
				<div className="keypad-amount-number">{amount}</div>
				<button onClick={() => setAmount(amount.substring(0, amount.length - 1))}>
					&#60;
				</button>
			</div>
			{[
				{ text: "€", class: "dark open-only", action: () => addDiscount() },
				saveButton,
				{ text: "7", class: "light open-only", action: () => setAmount(amount + "7") },
				{ text: "8", class: "light open-only", action: () => setAmount(amount + "8") },
				{ text: "9", class: "light open-only", action: () => setAmount(amount + "9") },
				{
					text: "X",
					class: "dark open-only",
					action: () => setCommand(command == "multi" ? false : "multi"),
					enabled: command == "multi",
				},
				preBillButton,
				{ text: "4", class: "light open-only", action: () => setAmount(amount + "4") },
				{ text: "5", class: "light open-only", action: () => setAmount(amount + "5") },
				{ text: "6", class: "light open-only", action: () => setAmount(amount + "6") },
				{
					text: "-",
					class: "dark open-only",
					action: () => setCommand(command == "minus" ? false : "minus"),
					enabled: command == "minus",
				},
				invoiceButton,
				{ text: "1", class: "light open-only", action: () => setAmount(amount + "1") },
				{ text: "2", class: "light open-only", action: () => setAmount(amount + "2") },
				{ text: "3", class: "light open-only", action: () => setAmount(amount + "3") },
				{
					text: "%-",
					class: "dark open-only",
					action: () => setCommand(command == "percminus" ? false : "percminus"),
					enabled: command == "percminus",
				},
				billButton,
				{ text: "0", class: "light open-only", action: () => setAmount(amount + "0") },
				{ text: "00", class: "light open-only", action: () => setAmount(amount + "00") },
				{ text: ".", class: "light open-only", action: () => setAmount(amount + ".") },
				{
					text: "%+",
					class: "dark open-only",
					action: () => setCommand(command == "percplus" ? false : "percplus"),
					enabled: command == "percplus",
				},
			].map((button, i) => (
				<button
					key={i}
					onClick={button.action}
					className={button.class + (button.enabled === false ? " disabled" : "")}
				>
					{button.payed != undefined && (
						<div
							className={
								"payed-icon" + (button.payed ? " text-success" : " text-error")
							}
						>
							<DotIcon />
						</div>
					)}
					{button.icon && <div className="icon">{button.icon}</div>}
					{button.text}
				</button>
			))}
		</div>
	);
}

export default Cart;
