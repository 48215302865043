import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import PopUp from "../PopUp";
import functions from "../../functions/functions";
import NumericPinPad from "../ui/NumericPinPad";
import EditIcon from "../../icons/EditIcon";

export default function RestPopup() {
	const { cart, checkout, setRestPopup, total, config, updateOrderFromCart } =
		useContext(AppContext);
	const [value, setValue] = useState(0);
	const [rest, setRest] = useState(0);

	useEffect(() => {
		const amount = value - total;
		if (amount > 0) setRest(amount);
		else setRest(0);
	}, [value]);

	const isEditing = () => {
		//console.log("isEditing", cart);
		let res = false;
		if (config.mode == "local" && cart["$loki"]) res = true;
		else if (cart.order_id) res = true;
		//console.log("isEditing", res);
		return res;
	};

	return (
		<PopUp
			size="sm"
			options={{
				onClose: () => setRestPopup(false),
				title: "Importo Contanti",
				content: (
					<>
						<h3 className="mb-2 text-center">
							Totale da pagare: <br></br>
							<strong>{functions.formatter.format(total)}</strong>
						</h3>
						<h3 className="text-center mb-0">Contanti ricevuti:</h3>
						{!value ? (
							<NumericPinPad onSubmit={(v) => setValue(parseFloat(v))} />
						) : (
							<div className="text-center">
								<h3 className="mb-0">
									<strong>{functions.formatter.format(value)} </strong>
								</h3>
								<button
									className="button square icon m-auto"
									onClick={() => setValue(0)}
								>
									<EditIcon />
								</button>
								<br></br>
								<hr></hr>
								<br></br>
								<h2>
									<strong>
										Calcolo resto: <br></br>
										{functions.formatter.format(rest)}
									</strong>
								</h2>
								<br></br>
								{value >= total && (
									<button
										className="button button-2"
										onClick={() => {
											setRestPopup(false);
											if (isEditing()) {
												updateOrderFromCart(
													{ id: 1 },
													true,
													config.prebill_on_checkout == 1
												);
											} else {
												checkout(
													false,
													{ id: 1 },
													true,
													false,
													config.prebill_on_checkout == 1
												);
											}
										}}
									>
										Scontrino
									</button>
								)}
							</div>
						)}
					</>
				),
			}}
		/>
	);
}
